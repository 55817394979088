import { getNamespaceName, getRegionName } from 'utils/IPUtils';

const config = {
  cps: {
    GBR: {
      develop: 'https://api.dev-raisin.co.uk/cps/api/v1',
      staging: 'https://api.staging-raisin.co.uk/cps/api/v1',
      onboarding: 'https://api.onboarding-raisin.co.uk/cps/api/v1',
      production: 'https://api.raisin.co.uk/cps/api/v1',
    },
    DEU: {
      develop: 'https://api.dev-weltsparen.de/cps/api/v1',
      staging: 'https://api.staging-weltsparen.de/cps/api/v1',
      onboarding: 'https://api.onboarding-weltsparen.de/cps/api/v1',
      production: 'https://api.weltsparen.de/cps/api/v1',
    },
  },
  cas: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/cas/public/v1',
      staging: 'https://api2.staging-raisin.co.uk/cas/public/v1',
      onboarding: 'https://api2.onboarding-raisin.co.uk/cas/public/v1',
      production: 'https://api2.raisin.co.uk/cas/public/v1',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/cas/public/v1',
      staging: 'https://api2.staging-weltsparen.de/cas/public/v1',
      onboarding: 'https://api2.onboarding-weltsparen.de/cas/public/v1',
      production: 'https://api2.weltsparen.de/cas/public/v1',
    },
  },
  scas: {
    GBR: {
      personal: 'https://scas-gbr-%s.raisin-dev.network/api/v2',
      develop: 'https://api2.dev-raisin.co.uk/scas/api/v2',
      staging: 'https://api2.staging-raisin.co.uk/scas/api/v2',
      onboarding: 'https://api2.onboarding-raisin.co.uk/scas/api/v2',
      production: 'https://api2.raisin.co.uk/scas/api/v2',
    },
    DEU: {
      personal: 'https://scas-deu-%s.raisin-dev.network/api/v2',
      develop: 'https://api2.dev-weltsparen.de/scas/api/v2',
      staging: 'https://api2.staging-weltsparen.de/scas/api/v2',
      onboarding: 'https://api2.onboarding-weltsparen.de/scas/api/v2',
      production: 'https://api2.weltsparen.de/scas/api/v2',
    },
  },
  pia: {
    develop: 'https://api2.dev-weltsparen.de/pia/api/v2',
    staging: 'https://api2.staging-weltsparen.de/pia/api/v2',
    onboarding: 'https://api2.onboarding-weltsparen.de/pia/api/v2',
    production: 'https://api2.weltsparen.de/pia/api/v2',
  },
  pom: {
    develop: 'https://api2.dev-weltsparen.de/pom/api/v1',
    staging: 'https://api2.staging-weltsparen.de/pom/api/v1',
    onboarding: 'https://api2.onboarding-weltsparen.de/pom/api/v1',
    production: 'https://api2.weltsparen.de/pom/api/v1',
  },
  dbs: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/dbs/v1',
      staging: 'https://api2.staging-raisin.co.uk/dbs/v1',
      onboarding: 'https://api2.onboarding-raisin.co.uk/dbs/v1',
      production: 'https://api2.raisin.co.uk/dbs/v1',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/dbs/v1',
      staging: 'https://api2.staging-weltsparen.de/dbs/v1',
      onboarding: 'https://api2.onboarding-weltsparen.de/dbs/v1',
      production: 'https://api2.weltsparen.de/dbs/v1',
    },
  },
  das: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/das/v1',
      staging: 'https://api2.staging-raisin.co.uk/das/v1',
      onboarding: 'https://api2.onboarding-raisin.co.uk/das/v1',
      production: 'https://api2.raisin.co.uk/das/v1',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/das/v1',
      staging: 'https://api2.staging-weltsparen.de/das/v1',
      onboarding: 'https://api2.onboarding-weltsparen.de/das/v1',
      production: 'https://api2.weltsparen.de/das/v1',
    },
  },
  dds: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/dds/api/1.0',
      staging: 'https://api2.staging-raisin.co.uk/dds/api/1.0',
      onboarding: 'https://api2.onboarding-raisin.co.uk/dds/api/1.0',
      production: 'https://api2.raisin.co.uk/dds/api/1.0',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/dds/api/1.0',
      staging: 'https://api2.staging-weltsparen.de/dds/api/1.0',
      onboarding: 'https://api2.onboarding-weltsparen.de/dds/api/1.0',
      production: 'https://api2.weltsparen.de/dds/api/1.0',
    },
  },
  ddsv2: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/dds/api/v2/public',
      staging: 'https://api2.staging-raisin.co.uk/dds/api/v2/public',
      onboarding: 'https://api2.onboarding-raisin.co.uk/dds/api/v2/public',
      production: 'https://api2.raisin.co.uk/dds/api/v2/public',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/dds/api/v2/public',
      staging: 'https://api2.staging-weltsparen.de/dds/api/v2/public',
      onboarding: 'https://api2.onboarding-weltsparen.de/dds/api/v2/public',
      production: 'https://api2.weltsparen.de/dds/api/v2/public',
    },
  },
  dts: {
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/dts/public/api/v2',
      staging: 'https://api2.staging-weltsparen.de/dts/public/api/v2',
      onboarding: 'https://api2.onboarding-weltsparen.de/dts/public/api/v2',
      production: 'https://api2.weltsparen.de/dts/public/api/v2',
    },
  },
  kci: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/kci/api/v1',
      staging: 'https://api2.staging-raisin.co.uk/kci/api/v1',
      onboarding: 'https://api2.onboarding-raisin.co.uk/kci/api/v1',
      production: 'https://api2.raisin.co.uk/kci/api/v1',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/kci/api/v1',
      staging: 'https://api2.staging-weltsparen.de/kci/api/v1',
      onboarding: 'https://api2.onboarding-weltsparen.de/kci/api/v1',
      production: 'https://api2.weltsparen.de/kci/api/v1',
    },
  },
  kss: {
    GBR: {
      development: 'https://api2.dev-raisin.co.uk/kss/api/v1',
      staging: 'https://api2.staging-raisin.co.uk/kss/api/v1',
      onboarding: 'https://api2.onboarding-raisin.co.uk/kss/api/v1',
      production: 'https://api2.raisin.co.uk/kss/api/v1',
    },
    DEU: {
      development: 'https://api2.dev-weltsparen.de/kss/api/v1',
      staging: 'https://api2.staging-weltsparen.de/kss/api/v1',
      onboarding: 'https://api2.onboarding-weltsparen.de/kss/api/v1',
      production: 'https://api2.weltsparen.de/kss/api/v1',
    },
  },
  tams: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/tams/v1',
      staging: 'https://api2.staging-raisin.co.uk/tams/v1',
      onboarding: 'https://api2.onboarding-raisin.co.uk/tams/v1',
      production: 'https://api2.raisin.co.uk/tams/v1',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/tams/v1',
      staging: 'https://api2.staging-weltsparen.de/tams/v1',
      onboarding: 'https://api2.onboarding-weltsparen.de/tams/v1',
      production: 'https://api2.weltsparen.de/tams/v1',
    },
  },
  ddoc: {
    GBR: {
      develop: 'https://api2.dev-raisin.co.uk/ddoc/public/api/v1',
      staging: 'https://api2.staging-raisin.co.uk/ddoc/public/api/v1',
      onboarding: 'https://api2.onboarding-raisin.co.uk/ddoc/public/api/v1',
      production: 'https://api2.raisin.co.uk/ddoc/public/api/v1',
    },
    DEU: {
      develop: 'https://api2.dev-weltsparen.de/ddoc/public/api/v1',
      staging: 'https://api2.staging-weltsparen.de/ddoc/public/api/v1',
      onboarding: 'https://api2.onboarding-weltsparen.de/ddoc/public/api/v1',
      production: 'https://api2.weltsparen.de/ddoc/public/api/v1',
    },
  },
};

// This is only required to generate correct api url for developer's namespace.
const mappingForDevNamespace = {
  cas: 'customer-administration-service',
  pia: 'pia',
  pom: 'pom',
  dbs: 'dbs',
  das: 'das',
};

const isPersonalNamespace = (namespace) =>
  namespace !== 'develop' &&
  namespace !== 'staging' &&
  namespace !== 'onboarding' &&
  namespace !== 'production';

export const getBaseUrlForPension = () => {
  const namespace = getNamespaceName();

  if (namespace === 'production') {
    return 'https://www.weltsparen.de/pension/v1';
  }

  return 'https://www.staging-weltsparen.de/pension/v1';
};

export const getBaseUrlForMicroservice = (microserviceName) => {
  let namespace = getNamespaceName();
  const regionName = getRegionName();

  if (microserviceName === 'scas' && namespace === 'development') {
    namespace = 'develop';
  } else if (isPersonalNamespace(namespace) && config?.[microserviceName]?.[regionName]?.personal) {
    return config[microserviceName][regionName].personal.replace('%s', namespace);
  }

  // if there is a namespace, microservice is region specific and it has a config
  if (
    namespace &&
    config[microserviceName][regionName] &&
    config[microserviceName][regionName][namespace]
  ) {
    return config[microserviceName][regionName][namespace];
  }

  // if there is a namespace and it has a config
  if (namespace && config[microserviceName][namespace]) {
    return config[microserviceName][namespace];
  }

  // if there is a namespace but there is no config for it => it means it's one of our developers namespaces
  if (namespace) {
    if (microserviceName === 'cas') {
      return `https://${
        mappingForDevNamespace[microserviceName]
      }-${regionName.toLowerCase()}-${namespace}.raisin-dev.network/public/v1`;
    }

    return `https://${mappingForDevNamespace[microserviceName]}-${namespace}.raisin-dev.network/api/v1`;
  }

  // default URL for any new microservice which is not defined in config object
  return `https://internal-api.weltsparen.de/${microserviceName}/api/v1`;
};
