import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import store from 'store2';
import { changePPTargetLink } from 'store/pensionProducts/actions';
import { changeUIState } from 'store/ui/actions';
import { setCustomerData, fetchCASCustomerData } from 'store/customer/actions';
import {
  CUSTOMER_ACCOUNT_STATUS,
  states as customerStates,
  validityStates,
  isGBRCustomer,
  PERSON_ROLE,
  isLoggedIn as isLoggedInFn,
} from 'utils/CustomerUtils';
import { LINKS } from 'components/Layout/menu';
import { bindActionCreators } from 'redux';
import { EXTERNAL_LINKS } from '../../components/Header/Navigation/menuLinks';
import { redirectExternal } from '../../components/Header/data/utils';

export const REFERRER_STORAGE_KEY = 'obs-login-referrer';

export class LoginOrchestrator extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      referenceAccount: PropTypes.object,
      isLoggedIn: PropTypes.bool.isRequired,
      bac_number: PropTypes.string,
      hasJustLoggedOut: PropTypes.bool,
      validity: PropTypes.shape({
        status: PropTypes.oneOf([...Object.keys(validityStates), '']),
      }),
    }).isRequired,
    casCustomer: PropTypes.shape({
      referenceAccount: PropTypes.object,
      status: PropTypes.oneOf([...Object.keys(CUSTOMER_ACCOUNT_STATUS), '']),
    }).isRequired,
    setCustomerData: PropTypes.func.isRequired,
    customerState: PropTypes.oneOf([...Object.keys(customerStates), '']),
    fetchCASCustomerData: PropTypes.func.isRequired,
    changeUIState: PropTypes.func.isRequired,
    changePPTargetLink: PropTypes.func.isRequired,
    featureFlags: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (isLoggedInFn()) {
      props.setCustomerData({ isLoggedIn: true });
    }
  }

  componentDidMount() {
    this.redirect();
  }

  componentDidUpdate() {
    this.redirect();
  }

  isLoginPage = () => {
    const {
      location: { pathname },
    } = this.props;

    return pathname === LINKS.LOGIN;
  };

  isInitialPasswordPage = () => {
    const {
      location: { pathname },
    } = this.props;

    return pathname === LINKS.SET_PASSWORD;
  };

  isSetReferenceAccountPage = () => {
    const {
      location: { pathname },
    } = this.props;

    return pathname?.includes(EXTERNAL_LINKS.GLOBAL.INITIAL_REFERENCE_ACCOUNT);
  };

  isLogoutPage = () => {
    const {
      location: { pathname },
    } = this.props;

    return pathname === LINKS.LOGOUT;
  };

  isCustomerDataValidatePage = () => {
    const {
      location: { pathname },
    } = this.props;

    return pathname.startsWith('/my-data/validate');
  };

  getCasCustomer = () => {
    const { fetchCASCustomerData, customer, casCustomer } = this.props;

    if (customer.bac_number && !casCustomer) {
      fetchCASCustomerData(customer.bac_number);
    }
  };

  checkCustomerReferenceAccount = () => {
    const { casCustomer } = this.props;

    // eslint-disable-next-line no-underscore-dangle
    const referenceAccount = casCustomer?._embedded?.referenceAccounts?.[0];
    const customerStatus = casCustomer?.status;

    return (
      customerStatus === CUSTOMER_ACCOUNT_STATUS.ACTIVE &&
      (!referenceAccount || !referenceAccount?.iban)
    );
  };

  isCustomerMissingFinancialInformation = () => {
    const { casCustomer } = this.props;

    // eslint-disable-next-line no-underscore-dangle
    const customerMainPerson = casCustomer?._embedded?.persons?.find((person) => {
      const roleName = person?.roles[0]?.name;

      return [PERSON_ROLE.ACCOUNT_HOLDER, PERSON_ROLE.LEGAL_REPRESENTATIVE].includes(roleName);
    });

    const customerFinancialInformation =
      customerMainPerson?.additionalFinancialInformation?.sourceOfFunds;

    return (
      customerMainPerson &&
      (!customerFinancialInformation?.originOfFunds ||
        !customerFinancialInformation?.annualIncome?.code)
    );
  };

  redirect() {
    const {
      customer: { isLoggedIn, bac_number },
      customer,
      casCustomer,
    } = this.props;

    // eslint-disable-next-line no-underscore-dangle
    const validity = casCustomer?._embedded?.dataValidity;

    this.getCasCustomer();

    this.saveDeepLink();

    // check for customer reference account
    if (
      bac_number &&
      isLoggedIn &&
      this.checkCustomerReferenceAccount() &&
      !this.isSetReferenceAccountPage() &&
      !this.isInitialPasswordPage()
    ) {
      redirectExternal(EXTERNAL_LINKS.GLOBAL.INITIAL_REFERENCE_ACCOUNT);

      return;
    }

    if (isLoggedIn && this.isCustomerMissingFinancialInformation() && isGBRCustomer(customer)) {
      redirectExternal(EXTERNAL_LINKS.GLOBAL.UPDATE_FINANCIAL_INFORMATION);

      return;
    }

    const comesFromCDVCFlow = store.session('isCDVCFlow');
    const referrerPath = sessionStorage.getItem(REFERRER_STORAGE_KEY);

    // check for validity of documents and redirect to customer-data zone app if documents are not valid
    // no need to attach the deeplink since it will be picked up and applied automatically
    // when the customer returns to this app
    if (isLoggedIn) {
      if (
        !this.isCustomerDataValidatePage() &&
        !isGBRCustomer(customer) &&
        !comesFromCDVCFlow &&
        (validity?.status === validityStates.EXPIRED ||
          validity?.status === validityStates.REVALIDATE)
      ) {
        redirectExternal(EXTERNAL_LINKS.GLOBAL.CDVC);
      } else if (referrerPath) {
        sessionStorage.removeItem(REFERRER_STORAGE_KEY);
        window.location.assign(referrerPath);
      }
    }
  }

  saveDeepLink() {
    const {
      location: { pathname },
      customer: { isLoggedIn, hasJustLoggedOut },
    } = this.props;

    // check for deeplink to save before redirecting to login
    // we should not save the current path as deep link in case customer
    // has just logged out
    // NOTE: make an exception for deeplinks coming from invest.raisin.com
    // see https://raisin-jira.atlassian.net/browse/PP-1104

    if (
      !isLoggedInFn() &&
      !isLoggedIn &&
      !this.isLoginPage() &&
      !this.isInitialPasswordPage() &&
      !this.isLogoutPage()
    ) {
      if (pathname !== '/' && !hasJustLoggedOut) {
        const deepLink = window.location.pathname + window.location.search + window.location.hash;

        sessionStorage.setItem(REFERRER_STORAGE_KEY, deepLink);
      }

      redirectExternal(EXTERNAL_LINKS.GLOBAL.LOGIN);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  customerState: state.customer.state,
  casCustomer: state.customer.casCustomerData,
  featureFlags: state.global.featureFlags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCustomerData,
      changeUIState,
      changePPTargetLink,
      fetchCASCustomerData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginOrchestrator));
