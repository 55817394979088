import React from 'react';

import Link from 'ui-lib/src/components/Link';

import { Translate } from 'components/I18n';

import { getForgotPasswordLink } from './utils';
import styles from './styles.scss';

export const ResetYourPassword = () => {
  return (
    <div className="row">
      <div className={`${styles.forgotPassword}`}>
        <Link className={styles.forgotPassword} href={getForgotPasswordLink()}>
          <Translate id="forgotPassword" />
        </Link>
      </div>
    </div>
  );
};

export default ResetYourPassword;
