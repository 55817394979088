const { getIntegrationServiceBaseUrl } = require('./utils/IntegrationService');
const { getBaseUrlForMicroservice, getBaseUrlForPension } = require('./utils/Microservices');

const apiBaseUrl = '/savingglobal/rest/open_api/v2';
const scasApiBaseUrl = getBaseUrlForMicroservice('scas');
const casApiBaseUrl = getBaseUrlForMicroservice('cas').replace('/public', '');
const casPublicApiBaseUrl = getBaseUrlForMicroservice('cas');
const cpsApiBaseUrl = getBaseUrlForMicroservice('cps');
const piaApiBaseUrl = getBaseUrlForMicroservice('pia');
const pomApiBaseUrl = getBaseUrlForMicroservice('pom');
const dbsApiBaseUrl = getBaseUrlForMicroservice('dbs');
const dasApiBaseUrl = getBaseUrlForMicroservice('das');
const ddsApiBaseUrl = getBaseUrlForMicroservice('dds');
const dtsApiBaseUrl = getBaseUrlForMicroservice('dts');
const kciApiBaseUrl = getBaseUrlForMicroservice('kci');
const kssApiBaseUrl = getBaseUrlForMicroservice('kss');
const tamsApiBaseUrl = getBaseUrlForMicroservice('tams');
const ddocApiBaseUrl = getBaseUrlForMicroservice('ddoc');
const pensionApiBaseUrl = getBaseUrlForPension();
const integrationApiBaseUrl = getIntegrationServiceBaseUrl();

const config = {
  imageBasePath: '/savingglobal/assets/img/',
  all: {
    basename: process.env.PUBLIC_PATH,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 3000,
  },
  postbox: {
    newMessage: '/PostBox/Compose/new',
    customerServiceEmail: 'kundenservice@weltsparen.de',
  },
  production: {
    baseUrl: '/savingglobal/rest',
    order: {
      edit: `${apiBaseUrl}/order/investment`, // fill form
      confirm: `${apiBaseUrl}/order/investment/confirm`, // mTan
      getRevoke: `${apiBaseUrl}/deposit/order/revoke/{depositNumber}`,
      postRevoke: `${apiBaseUrl}/deposit/order/revoke`,
    },
    depositOrder: {
      edit: `${apiBaseUrl}/deposit/order`, // fill form
      confirm: `${apiBaseUrl}/deposit/order/confirm`, // mTan
    },
    buy: {
      edit: `${apiBaseUrl}/order/investment/buy`, // fill form
      confirm: `${apiBaseUrl}/order/investment/buy/confirm`, // mTan
    },
    sell: {
      edit: `${apiBaseUrl}/order/investment/sell`, // fill form
      confirm: `${apiBaseUrl}/order/investment/sell/confirm`, // mTan
    },
    auth: {
      configuration: `${apiBaseUrl}/auth/configuration/{base64Email}`,
    },
    cognito: {
      configuration: `${apiBaseUrl}/cognito/configuration/{base64Email}`,
    },
    customer: {
      login: `${apiBaseUrl}/login`,
      data: `${apiBaseUrl}/customer`,
      investments: `${apiBaseUrl}/customer/investments`,
      deposit: `${apiBaseUrl}/customer/investments/{id}`,
      mtan: {
        block: `${apiBaseUrl}/customer/lock/mtan`,
      },
      password_initial: {
        get: `${apiBaseUrl}/password/initial/customer/info/{token}`,
        post: `${apiBaseUrl}/password/initial/set`,
      },
      password: {
        edit: `${apiBaseUrl}/customer/pin`,
      },
      mail_consents: `/mail/consents`,
      mailing_preferences: `${apiBaseUrl}/customer/mailing_preferences`,
      marketing_mailing_preferences: `${apiBaseUrl}/customer/marketing_mailing_preferences`,
      features: `${apiBaseUrl}/customer/features`, // feature switches
      documents: `${apiBaseUrl}/customer/investment/documents`,
      account: {
        block: `${apiBaseUrl}/customer/lock`,
        transactions: `${apiBaseUrl}/customer/account/transactions`,
        download: `${apiBaseUrl}/customer/account/transactions/export`,
      },
      investment: `${apiBaseUrl}/cockpit/investment`,
      investmentHistory: `${apiBaseUrl}/customer/investment/history`,
      postDocuments: {
        identification: `${apiBaseUrl}/documents/upload/IDD`,
        proof_of_address: `${apiBaseUrl}/documents/upload/POA`,
      },
      timeline: {
        orders: `${apiBaseUrl}/customer/investments/cockpit/timeline/orders`,
        rebalancing: `${apiBaseUrl}/customer/investments/cockpit/timeline/rebalancing_events`,
        fees: `${apiBaseUrl}/customer/investments/cockpit/timeline/fee_transactions`,
        dividends: `${apiBaseUrl}/customer/investments/cockpit/timeline/distribution_events`,
      },
      cobranding: `${integrationApiBaseUrl}/cobranding`,
      nationalIdentifier: `${apiBaseUrl}/customer/national-identifier`,
      identification: {
        get: `${apiBaseUrl}/customer/identification`,
        postidentDocumentDownload: `${apiBaseUrl}/customer/identification/postident/document`,
        idnow: {
          sendSms: `${apiBaseUrl}/customer/identification/idnow/send/sms`,
          phoneChange: `${apiBaseUrl}/customer/identification/idnow/phone/change`,
        },
      },
      productAccess: `${apiBaseUrl}/customer/product/access`,
      verifications: {
        userDetails: `${scasApiBaseUrl}/verifications/{verificationId}/userDetails`,
        sendOTP: `${scasApiBaseUrl}/verifications/{verificationId}/attempts`,
        verifyOTP: `${scasApiBaseUrl}/verifications/{verificationId}/attempts/{nonce}`,
      },
    },
    products: {
      investment_products: `${apiBaseUrl}/products/investment_portfolios`,
      investmentProduct: `${apiBaseUrl}/products/investment_portfolios/{id}`,
      deposits: `${apiBaseUrl}/products/deposits`,
      deposit: `${apiBaseUrl}/products/deposits/{id}`,
      partner_banks: `${apiBaseUrl}/partner_banks`,
      topOffers: `${apiBaseUrl}/products/deposits/top_offers`,
    },
    deposits: {
      autoProlongation: {
        disable: `${apiBaseUrl}/deposit/autoprolongation/disable`,
      },
      prolongation: {
        fetch: `${apiBaseUrl}/deposit/prolongation/{depositNumber}`,
        submit: `${apiBaseUrl}/deposit/prolongation`,
        confirm: `${apiBaseUrl}/deposit/prolongation/confirm`,
      },
      earlyTermination: {
        edit: `${apiBaseUrl}/deposit/early_termination/{depositNumber}`,
        submit: `${apiBaseUrl}/deposit/early_termination/`,
        confirm: `${apiBaseUrl}/deposit/early_termination/confirm`,
      },
      depositAccountApplicationForm: `${apiBaseUrl}/deposit/deposit_application_form/{depositNumber}/{applicationType}`,
    },
    loggedOut: '/login',
    cms: '/savingglobal/rest/open_api/v2/cms/documents',
    voice_call: `${apiBaseUrl}/signature/delivery`,
    savingsPlan: {
      status: `${apiBaseUrl}/customer/investment/savings_plan`,
      edit: {
        get: `${apiBaseUrl}/order/investment/savings_plan`,
        post: `${apiBaseUrl}/order/investment/savings_plan`,
      },
      confirm: `${apiBaseUrl}/order/investment/savings_plan/confirm`,
    },
    overnight: {
      transactions: `${apiBaseUrl}/deposit/transactions/{deposit_id}`,
      payIn: {
        edit: `${apiBaseUrl}/deposit/pay_in`,
        confirm: `${apiBaseUrl}/deposit/pay_in/confirm`,
      },
      savingsPlan: {
        edit: `${apiBaseUrl}/deposit/savings_plan`,
        confirm: `${apiBaseUrl}/deposit/savings_plan/confirm`,
      },
      payOut: {
        edit: `${apiBaseUrl}/deposit/pay_out`,
        confirm: `${apiBaseUrl}/deposit/pay_out/confirm`,
      },
    },
    about: {
      pricing: `${apiBaseUrl}/about/pricing`,
      security: `${apiBaseUrl}/about/security`,
      terms: `${apiBaseUrl}/about/terms`,
      imprint: `${apiBaseUrl}/about/imprint`,
      privacy: `${apiBaseUrl}/about/privacy`,
      document_download: `${apiBaseUrl}/about/document_download`,
    },
    mailbox: {
      all_folders: `${apiBaseUrl}/mailbox`,
      documents: `${apiBaseUrl}/mailbox/documents`,
      inbox: `${apiBaseUrl}/mailbox/inbox`,
      send: `${apiBaseUrl}/mailbox/send`,
      sent: `${apiBaseUrl}/mailbox/sent`,
      current_message: `${apiBaseUrl}/mailbox/{folderName}/message/{id}`,
      postApplicationDocument: `${apiBaseUrl}/mailbox/upload/{type}/document`,
      unreadMessagesCount: `${cpsApiBaseUrl}/threads/unread-count`,
    },
    dashboard: {
      pensionProducts: `${apiBaseUrl}/pension/customer/products`,
      pensionProductsMigrated: `${pensionApiBaseUrl}/products`,
    },
    logout: `${apiBaseUrl}/logout`,
    depositStatus: `${apiBaseUrl}/replatforming/deposits/{deposit_id}/status`,
    pia: {
      productInstances: `${piaApiBaseUrl}/product_instance`,
      productInstanceDetails: `${piaApiBaseUrl}/product_instance/{invNumber}/details`,
      privateEquityMetrics: `${piaApiBaseUrl}/product_instance/{invNumber}/metrics`,
    },
    pom: {
      customerOrders: `${pomApiBaseUrl}/orders`,
      portfolioOrders: `${pomApiBaseUrl}/orders`,
      validateOrder: `${pomApiBaseUrl}/orders/{order_domain_id}/validate`,
      products: `${pomApiBaseUrl}/products`,
    },
    dbs: {
      customerDeposits: `${dbsApiBaseUrl}/deposits`,
      deposit: `${dbsApiBaseUrl}/deposits/{deposit_id}`,
      productsAccess: `${dbsApiBaseUrl}/deposits/types`,
      productsTypes: `${dbsApiBaseUrl}/products/types`,
      autoRenewal: {
        disable: `${dbsApiBaseUrl}/deposits/{deposit_id}/orders/renewal`,
      },
      topOffers: `${dbsApiBaseUrl}/products`,
    },
    das: {
      customerDeposits: `${dasApiBaseUrl}/deposits`,
      deposit: `${dasApiBaseUrl}/deposits/{deposit_id}`,
      depositTransactions: `${dasApiBaseUrl}/deposits/{deposit_id}/transactions`,
      depositsBalance: `${dasApiBaseUrl}/deposits/balance`,
    },
    dts: {
      depositTaxRequirements: `${dtsApiBaseUrl}/tax/requirements/{bac}`,
    },
    documentsdepot: `${ddsApiBaseUrl}/public/documents`,
    documentdepot: `${ddsApiBaseUrl}/public/documents/{document_id}`,
    cas: {
      public: {
        customerData: `${casPublicApiBaseUrl}/customers/{customer_id}`,
      },
      // To be deprecated
      customerData: `${casApiBaseUrl}/customers/{customer_id}`,
    },
    kci: {
      customerKYCStatus: `${kciApiBaseUrl}/public/status`,
    },
    kss: {
      customerKYCStatus: `${kssApiBaseUrl}/public/status`,
    },
    tams: {
      transactionAccount: `${tamsApiBaseUrl}/accounts/{accountId}`,
      accounts: `${tamsApiBaseUrl}/accounts`,
    },
    ddoc: {
      documentRequirements: `${ddocApiBaseUrl}/deposits/documents/requirements`,
      accountStatement: `${ddocApiBaseUrl}/deposits/documents/account-statement`,
    },
  },
  ajaxStatus: {
    success: 'success',
    failure: 'failure',
    notPosted: 'not-posted',
    posting: 'posting',
  },
  responseStatus: {
    loggedOut: 403,
    forbidden: 403,
  },
  investmentProducts: {
    initialAmount: 10000,
    initialSPAmount: 100,
  },
  abTest: {
    taxDocValidation: {
      prodID: 'VRUAUDJQQDmClGDSp9prEw',
      testID: 'sNNB-mZRT7iZbvyCY7FOUg',
    },
  },
};

if (process.env.NODE_ENV === 'local' && !process.env.OBS_LOCAL) {
  const baseUrl = '/__mocks__';

  config.local = {
    order: {
      edit: `${baseUrl}/order-edit.json`,
      confirm: `${baseUrl}/order-confirm.json`,
      getRevoke: `${baseUrl}/revokeGET.json`,
      postRevoke: `${baseUrl}/revokePOST.json`,
    },
    depositOrder: {
      edit: `${baseUrl}/order-edit.json`,
      confirm: `${baseUrl}/order-confirm.json`,
    },
    buy: {
      edit: `${baseUrl}/buy-edit.json`,
      confirm: `${baseUrl}/buy-confirm.json`,
    },
    sell: {
      edit: `${baseUrl}/sell-edit.json`,
      confirm: `${baseUrl}/sell-confirm.json`,
    },
    products: {
      investment_products: `${baseUrl}/investment_portfolios.json`,
      investment_products_justetf: `${baseUrl}/justEtf_portfolios.json`,
      investmentProduct: `${baseUrl}/investment_portfolio.json`,
      singlePortfolios: {
        WI01: `${baseUrl}/WI01.json`,
        WI02: `${baseUrl}/WI02.json`,
        WI03: `${baseUrl}/WI03.json`,
        WI04: `${baseUrl}/WI04.json`,
        JC01: `${baseUrl}/JC01.json`,
        JC02: `${baseUrl}/JC02.json`,
        JC03: `${baseUrl}/JC03.json`,
        JC04: `${baseUrl}/JC04.json`,
        JD02: `${baseUrl}/JD02.json`,
        JD03: `${baseUrl}/JD03.json`,
        JF02: `${baseUrl}/JF02.json`,
        JF03: `${baseUrl}/JF03.json`,
        JN02: `${baseUrl}/JN02.json`,
        JN03: `${baseUrl}/JN03.json`,
      },
      deposits: `${baseUrl}/deposits_products.json`,
      deposit: `${baseUrl}/deposit_product.json`,
      partner_banks: `${baseUrl}/partner_banks.json`,
      topOffers: `${baseUrl}/products_top_offers.json`,
    },
    deposits: {
      autoProlongation: {
        disable: `${baseUrl}/disableAutoProlongation.json`,
      },
      prolongation: {
        fetch: `${baseUrl}/prolongation.json`,
        submit: `${baseUrl}/prolongation-mtan.json`,
        confirm: `${baseUrl}/prolongation-confirm.json`,
      },
      earlyTermination: {
        edit: `${baseUrl}/earlyTermination.json`,
        submit: `${baseUrl}/earlyTerminationSubmit.json`,
        confirm: `${baseUrl}/earlyTerminationConfirm.json`,
      },
      depositAccountApplicationForm: `${baseUrl}/depositAccountApplicationForm.json`,
    },
    auth: {
      configuration: `${baseUrl}/keycloakConfig.json`,
    },
    customer: {
      login: `${baseUrl}/login.json`,
      logout: `${baseUrl}/customerData.json`,
      data: `${baseUrl}/customerData.json`,
      mtan: {
        block: `${baseUrl}/customerMTanBlock`, // No mock atm
      },
      password_initial: {
        get: `${baseUrl}/customerDataToken.json`,
        post: `${baseUrl}/customerDataToken.json`,
      },
      password: {
        edit: `${baseUrl}/customer-pin-edit.json`,
      },
      mail_consents: '/mail/consents',
      mailing_preferences: `${baseUrl}/customerData.json`,
      marketing_mailing_preferences: `${baseUrl}/customerData.json`,
      investments: `${baseUrl}/customerInvestments.json`,
      deposit: `${baseUrl}/customerDeposit.json`,
      features: `${baseUrl}/features.json`,
      account: {
        block: `${baseUrl}/customer-block.json`,
        transactions: `${baseUrl}/transactionAccountTransactions.json`,
        download: '', // no mock
      },
      investment: `${baseUrl}/customer_portfolio-2.json`,
      investmentHistory: `${baseUrl}/investment_history.json`,
      documents: `${baseUrl}/investment_documents.json`,
      payout: {
        edit: `${baseUrl}/customer-payout-edit.json`,
        submit: `${baseUrl}/customer-payout-submit.json`,
        confirm: `${baseUrl}/customer-payout-confirm.json`,
      },
      postDocuments: {
        identification: `${baseUrl}/documents/upload/IDD`, // no mock
        proof_of_address: `${baseUrl}/documents/upload/POA`, // no mock
      },
      taxExemption: {
        get: `${baseUrl}/tax_exemptions.json`,
        post: `${baseUrl}/tax_exemptions.json`,
      },
      referenceAccount: {
        edit: `${baseUrl}/reference.json`,
        mtan: `${baseUrl}/reference-confirm.json`,
        initial: `${baseUrl}/reference-initial.json`,
      },
      timeline: {
        orders: `${baseUrl}/timelineOrders.json`,
        rebalancing: `${baseUrl}/timelineRebalancingEvents.json`,
        fees: `${baseUrl}/timelineFeeTransactions.json`,
        dividends: `${baseUrl}/timelineDistributionEvents.json`,
      },
      cobranding: `${baseUrl}/cobranding.json`,
      nationalIdentifier: `${baseUrl}/nationalIdentifiers.json`,
      identification: {
        get: `${baseUrl}/identification.json`,
        postidentDocumentDownload: 'this-is-never-used',
        idnow: {
          sendSms: `${baseUrl}/idnowSendSms.json`, // no mock
          phoneChange: `${baseUrl}/phoneChange`, // no mock
        },
      },
      productAccess: `${baseUrl}/productAccess.json`,
      verifications: {
        userDetails: `${baseUrl}/verifications-user-details.json`,
        sendOTP: `${baseUrl}/verifications-send-otp.json`,
        verifyOTP: `${baseUrl}/verifications-verify-otp.json`,
      },
    },
    savingsPlan: {
      status: `${baseUrl}/savingsPlan-status.json`,
      edit: {
        get: `${baseUrl}/savingsPlan-edit-GET.json`,
        post: `${baseUrl}/savingsPlan-edit-POST.json`,
      },
      confirm: `${baseUrl}/savingsPlan-confirm.json`,
    },
    voice_call: `${baseUrl}/signature/delivery`, // no mock
    overnight: {
      transactions: `${baseUrl}/overnight-transactions.json`,
      transactions1: `${baseUrl}/overnight-transactions1.json`,
      payIn: {
        edit: `${baseUrl}/overnight-pay-in.json`,
        confirm: `${baseUrl}/overnight-pay-in-confirm.json`,
      },
      savingsPlan: {
        edit: `${baseUrl}/overnight-savings-plan-edit.json`,
        confirm: `${baseUrl}/overnight-savings-plan-confirm.json`,
      },
      payOut: {
        edit: `${baseUrl}/overnight-pay-out.json`,
        confirm: `${baseUrl}/overnight-pay-out-confirm.json`,
      },
    },
    about: {
      pricing: `${baseUrl}/about-pricing.json`,
      security: `${baseUrl}/about-security.json`,
      terms: `${baseUrl}/about-terms.json`,
      imprint: `${baseUrl}/about-imprint.json`,
      privacy: `${baseUrl}/about-privacy.json`,
      document_download: `${baseUrl}/about-document_download.json`,
    },
    mailbox: {
      all_folders: `${baseUrl}/mailbox.json`,
      documents: `${baseUrl}/mailbox-documents.json`,
      inbox: `${baseUrl}/mailbox-inbox.json`,
      sent: `${baseUrl}/mailbox-sent.json`,
      send: `${baseUrl}/customerData.json`,
      current_message: `${baseUrl}/mailbox-{folderName}-message-{id}.json`,
      postApplicationDocument: `${baseUrl}/customerData.json`,
      unreadMessagesCount: `${baseUrl}/mailbox-unread-messages-count.json`,
    },
    dashboard: {
      pensionProducts: `${baseUrl}/pensionProductsDashboard.json`,
      pensionProductsMigrated: `${baseUrl}/pensionProductsDashboardMigrated.json`,
    },
    logout: `${baseUrl}/logout.json`,
    pension: {
      shareData: `${baseUrl}/pension-customer-sharedata.json`,
      token: `${baseUrl}/pension-customer-token.json`,
      shareSession: `${baseUrl}/pension-customer-shareSession.json`,
    },
    pia: {
      productInstances: `${baseUrl}/piaProductInstances.json`,
      productInstanceDetails: `${baseUrl}/piaProductInstanceDetails.json`,
      privateEquityMetrics: `${baseUrl}/piaPrivateEquityMetrics.json`,
    },
    pom: {
      customerOrders: `${baseUrl}/pomCustomerOrders.json`,
      portfolioOrders: `${baseUrl}/pomPortfolioOrders.json`,
      validateOrder: `${baseUrl}/order-validate.json`,
      products: `${baseUrl}/pomProducts.json`,
    },
    dbs: {
      customerDeposits: `${baseUrl}/dbsCustomerDeposits.json`,
      deposit: `${baseUrl}/dbsDeposit.json`,
      productsAccess: `${baseUrl}/dbsDepositsTypes.json`,
      productsTypes: `${baseUrl}/dbsProductsTypes.json`,
      autoRenewal: {
        disable: `${baseUrl}/disableDbsDepositRenewal.json`,
      },
      topOffers: `${baseUrl}/dbsProductsTopOffers.json`,
    },
    depositStatus: `${baseUrl}/depositStatus.json`,
    das: {
      customerDeposits: `${baseUrl}/dasCustomerDeposits.json`,
      deposit: `${baseUrl}/dasDeposit.json`,
      depositTransactions: `${baseUrl}/dasDepositTransactions.json`,
      depositTransactions1: `${baseUrl}/dasDepositTransactions1.json`,
      depositsBalance: `${baseUrl}/depositsBalance.json`,
    },
    dts: {
      depositTaxRequirements: `${baseUrl}/dtsDepositTaxRequirements.json`,
    },
    documentsdepot: `${baseUrl}/depositDocuments.json`,
    documentdepot: `${baseUrl}/depositDocument.json`,
    cas: {
      customerData: `${baseUrl}/casCustomerData.json`,
      public: {
        customerData: `${baseUrl}/casCustomerData.json`,
      },
    },
    kci: {
      customerKYCStatus: `${baseUrl}/kciCustomerKYCStatus.json`,
    },
    kss: {
      customerKYCStatus: `${baseUrl}/kssCustomerKYCStatus.json`,
    },
    tams: {
      transactionAccount: `${baseUrl}/transaction-account-with-balance.json`,
      accounts: `${baseUrl}/transaction-account-without-balance.json`,
    },
    ddoc: {
      documentRequirements: `${baseUrl}/ddocDocumentRequirements.json`,
    },
  };
}

module.exports = config;
