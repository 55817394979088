import React from 'react';

import { LocalizeConsumer, Translate } from 'components/I18n';
import { isCOM } from '../../../utils/Locale';

export const getTranslation = (translationKey) => {
  return <Translate id={translationKey} raw />;
};

export const getLocalizedMoney = (value, currency, position) => (
  <LocalizeConsumer>
    {(ctx) =>
      ctx.localizeMoney(value, {
        currencyDisplay: 'code',
        currency,
        currencyPosition: position,
        minimumFractionDigits: 2,
      })
    }
  </LocalizeConsumer>
);

export const getBasePath = () => {
  const countryBasePaths = {
    'en-us': '/en', // com's obs locale
    de: isCOM() ? '/de-de': '',
    at: isCOM() ? '/de-at': '',
    'de-at': isCOM() ? '/de-at': '',
    gb: '',
    ie: '',
    es: '',
    nl: '',
    fr: isCOM() ? '/fr-fr': '',
    'pl-pl': '/pl-pl',
    'fi-fi': '/fi-fi',
  };

  const obsLocale = localStorage.getItem('obs-locale');

  return countryBasePaths[obsLocale?.toLowerCase()] || '';
};

export const getUrl = (url) => {
  return process.env.NODE_ENV === 'production' ? `${getBasePath()}/savingglobal${url}` : url;
};

export const getExternalUrl = (path) => {
  return `${getBasePath()}${path}`;
};

export const redirectExternal = (path) => {
  window.location.assign(getExternalUrl(path));
};
