import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { fetchKSSCustomerKYCStatus } from 'store/customer/actions';
import Alert from 'ui-lib/src/components/Alert';
import Link from 'ui-lib/src/components/Link';
import Paragraph from 'ui-lib/src/components/Paragraph';

import { Translate } from 'components/I18n';

import { getExternalUrl } from '../Header/data/utils';
import { EXTERNAL_LINKS } from '../Header/Navigation/menuLinks';
import styles from './styles.scss';

const MissingIDDNotice = ({ bacNumber, fetchKSSCustomerKYCStatus, kssCustomerKYCStatus }) => {
  useEffect(() => {
    fetchKSSCustomerKYCStatus(bacNumber);
  }, [bacNumber, fetchKSSCustomerKYCStatus]);

  const notice = useMemo(() => {
    if (!kssCustomerKYCStatus) return null;
    const { id_expiration } = kssCustomerKYCStatus;

    let key;
    let type;

    if (id_expiration === 'EXPIRED') {
      key = 'alert.iddExpired';
      type = 'warning';
    } else if (id_expiration === 'EXPIRING_SOON') {
      key = 'alert.iddExpiringSoon';
      type = 'info';
    } else return null;

    return {
      key,
      type,
      body: `${key}.body`,
      title: `${key}.title`,
      button: `${key}.button`,
    };
  }, [kssCustomerKYCStatus]);

  if (!notice) return null;

  return (
    <Alert
      id={notice.key}
      className={styles.alertIDD}
      type={notice.type}
      key={notice.key}
      cta={
        <Link
          href={getExternalUrl(EXTERNAL_LINKS.IDENTIFICATION.UPLOAD_ID_DOCUMENT)}
          suffixIcon="chevron-right"
        >
          <Translate id={notice.button} />
        </Link>
      }
      showDefault
    >
      <Paragraph>
        <strong>
          <Translate id={notice.title} />
        </strong>
      </Paragraph>
      <Paragraph>
        <Translate id={notice.body} />
      </Paragraph>
    </Alert>
  );
};

const mapStateToProps = (state) => {
  return {
    kssCustomerKYCStatus: state.customer.kssCustomerKYCStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchKSSCustomerKYCStatus: (bacNumber) => dispatch(fetchKSSCustomerKYCStatus(bacNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissingIDDNotice);
