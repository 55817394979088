/* eslint-disable no-console */
import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TranslationsProvider } from 'components/I18n';
import {
  countries,
  getCountryByLocalStorageLocale,
  hasCookieForMobile,
  isAUTCustomer,
  isDEUCustomer,
  isGBRCustomer,
  isIRLCustomer,
  isNLDCustomer,
} from 'utils/CustomerUtils';
import TrustPilotBar from './TrustPilotBar';
import ContactInformation from './ContactInformation';
import Imprint from './Imprint';
import MobileBanner from './MobileBanner';
import Disclaimer from './Disclaimer';
import { getTrustpilotRatings } from './utils';
import styles from './styles.scss';

export const Footer = memo(
  ({ country, isBusinessCustomer, isCustomerLoggedIn }) => {
    const [trustpilotRatings, setTrustpilotRatings] = useState(null);
    const DEFAULT_LOCALE = 'EUR';

    const customer = { default_address: { country } };
    const localeBaseOnCountry = countries[country] ?? DEFAULT_LOCALE;

    const shouldRenderTrustPilot = isDEUCustomer(customer) || isAUTCustomer(customer);
    const shouldRenderMobileBanner =
      isCustomerLoggedIn && !isIRLCustomer(customer) && !hasCookieForMobile();
    const shouldRenderTopLine = shouldRenderTrustPilot && !isCustomerLoggedIn;
    const shouldRenderDisclaimer =
    (isGBRCustomer(customer) || isIRLCustomer(customer)) &&
    !isNLDCustomer(customer) &&
    !isDEUCustomer(customer) &&
    !isAUTCustomer(customer);

    useEffect(() => {
        getTrustpilotRatings(setTrustpilotRatings);
      }
    , []);

    return (
      <TranslationsProvider
        translations={{
          'de-AT': () => import('./__translations__/de-AT.json'),
          'en-GB': () => import('./__translations__/en-GB.json'),
          'en-IE': () => import('./__translations__/en-IE.json'),
          'en-US': () => import('./__translations__/en-US.json'),
          'pl-PL': () => import('./__translations__/pl-PL.json'),
          'fi-FI': () => import('./__translations__/fi-FI.json'),
          de: () => import('./__translations__/de.json'),
          es: () => import('./__translations__/es.json'),
          fr: () => import('./__translations__/fr.json'),
          nl: () => import('./__translations__/nl.json'),
        }}
        id="footer"
      >
        <footer className={styles.wrapper}>
          {shouldRenderTrustPilot && <TrustPilotBar trustpilotData={trustpilotRatings} />}

          <ContactInformation
            country={localeBaseOnCountry}
            isBusinessCustomer={isBusinessCustomer}
            isCustomerLoggedIn={isCustomerLoggedIn}
            isTrustPilotAvailable={shouldRenderTrustPilot}
          />

          {shouldRenderMobileBanner && <MobileBanner country={localeBaseOnCountry} />}

          <Imprint
            country={localeBaseOnCountry}
            isCustomerLoggedIn={isCustomerLoggedIn}
            shouldRenderTopLine={shouldRenderTopLine}
          />

          {shouldRenderDisclaimer && <Disclaimer customer={customer} />}
        </footer>
      </TranslationsProvider>
    );
  },
);

Footer.propTypes = {
  country: PropTypes.string.isRequired,
  isBusinessCustomer: PropTypes.bool.isRequired,
  isCustomerLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  country: state.customer.default_address?.country || getCountryByLocalStorageLocale(),
  isBusinessCustomer: state.customer.is_company_customer,
  isCustomerLoggedIn: state.customer.isLoggedIn,
});


export default connect(mapStateToProps, null)(Footer);
