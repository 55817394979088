import * as types from '../../../actionTypes';
import { createImprovedAsyncAction } from '../../../actionCreators';

export const fetchPiaProductInstances = (bac) => {
  return createImprovedAsyncAction({
    type: types.PIA_FETCH_PRODUCTS_INSTANCES,
    path: 'pia.productInstances',
    settings: {
      params: {
        customer_domain_id: bac,
        pageId: window.location.pathname + window.location.hash.replace('#/', ''),
      },
      useJwt: true,
    },
  });
};

export const fetchPiaProductInstanceDetails = (invNumber) => {
  return createImprovedAsyncAction({
    type: types.PIA_FETCH_PRODUCT_INSTANCE_DETAILS,
    path: 'pia.productInstanceDetails',
    dynamicPath: { invNumber },
    settings: {
      useJwt: true,
    },
  });
};

export const fetchPivateEquityMetrics = ({ invNumber }) => {
  return createImprovedAsyncAction({
    type: types.PIA_FETCH_PRIVATE_EQUITY_METRICS,
    path: 'pia.privateEquityMetrics',
    dynamicPath: { invNumber },
    settings: {
      useJwt: true,
    },
  });
};
